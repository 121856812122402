$desktop: 840px;

$duration: 0.2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

:root {
  --mainWhite: #ffffff;
  --mainBlack: #000000;
}

body {
  font-family: "Montserrat";
  background-color: var(--mainBlack);
  color: var(--mainWhite);
}

/* Navbar */
.navbar {
  @include desktop {
    grid-gap: 20px !important;
    grid-template-columns: 50px 260px 1fr repeat(3, 200px) !important;
    grid-template-rows: 50px !important;
    grid-template-areas: ". logo . tours book about" !important;
  }
}

.navbar-logo {
  @include desktop {
    margin-top: 7px;
  }
}

.navbar-link {
  @include desktop {
    text-align: left !important;
    border-bottom: none !important;

    a {
      color: #a3a3a3 !important;

      &:hover {
        color: white !important;
        filter: drop-shadow(0px 0px 5px var(--mainWhite));
      }
    }
  }
}

/* Home */
.home {
  @include desktop {
    grid-template-columns: 100px 1fr 1fr 100px !important;
    grid-template-rows: repeat(4, auto) 50px auto !important;
    grid-template-areas:
      ". main photo ."
      ". sub photo ."
      ". button photo ."
      ". . photo ."
      ". line line ."
      ". review review ." !important;
  }
}

.home-main-header {
  @include desktop {
    h1 {
      font-size: 60px !important;
    }
  }
}

.home-sub-header {
  @include desktop {
    font-size: 36px !important;
    margin-left: 0 !important;
  }
}

.home-photo {
  @include desktop {
    img {
      display: block;
      position: relative;
      margin-right: auto;
      margin-left: auto;
      margin-top: 50px;
    }
  }
}

.home-click {
  @include desktop {
    button {
      font-size: 32px !important;
      border-radius: 40px !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      padding-right: 45px !important;
      padding-left: 45px !important;
    }
  }
}

.home-line {
  @include desktop {
    hr {
      margin-top: 30px;
      max-width: 2000px !important;
    }
  }
}

/* Tours */
.tours {
  @include desktop {
    grid-template-columns: 100px 1fr 100px !important;
    grid-template-rows: 80px 100px 1fr 100px 1fr !important;
    grid-template-areas:
      ". header ."
      ". lisbon-header ."
      ". lisbon ."
      ". sintra-header ."
      ". sintra ." !important;
  }
}

.tours-header {
  @include desktop {
    font-size: 48px !important;
    text-align: left !important;
  }
}

.tours-subheader {
  @include desktop {
    font-size: 36px !important;
    margin-left: 0 !important;
  }
}

.tours-lisbon,
.tours-sintra {
  @include desktop {
    grid-template-columns: repeat(auto-fit, minmax(370px, 400px)) !important;
    grid-template-rows: 260px !important;

    img {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

/* Book */
.book {
  @include desktop {
    grid-template-columns: 1fr 2px 1fr !important;
    grid-template-rows: 70px 730px !important;
    grid-template-areas:
      "book-header book-line book-form"
      "book-description book-line book-form" !important;
  }
}

.book-header {
  @include desktop {
    font-size: 48px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

.book-description {
  @include desktop {
    font-size: 36px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

.book-form {
  @include desktop {
    zoom: 115%;
  }
}

.book-line {
  hr {
    @include desktop {
      max-width: 0.5px !important;
      height: 710px !important;
    }
  }
}

.book-comment {
  @include desktop {
    p {
      right: -5px !important;
    }

    textarea {
      right: -5px !important;
      height: 100px !important;
      width: 250px !important;
    }
  }
}

/* About */
.about {
  @include desktop {
    grid-template-columns: 400px 1fr 100px !important;
    grid-template-rows: repeat(4, auto) 80px 1fr 30px 120px 120px !important;
    grid-template-areas:
      "header header ."
      "sub-header-years sub-header-years ."
      "text-years text-years ."
      "sub-header-guide sub-header-guide ."
      "avatar name ."
      "avatar description ."
      "line line line"
      "follow-header follow-header ."
      "icons icons ." !important;
  }
}

.about-header {
  @include desktop {
    h2 {
      font-size: 48px !important;
      text-align: left !important;
      margin-left: 100px !important;
    }
  }
}

.about-subheader {
  @include desktop {
    h3 {
      font-size: 30px !important;
      text-align: left !important;
      margin-left: 100px !important;
    }
  }
}

.about-name {
  @include desktop {
    font-size: 40px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

.about-description {
  @include desktop {
    font-size: 26px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

.about-text {
  @include desktop {
    font-size: 26px !important;
    text-align: left !important;
    margin-left: 120px !important;
  }
}

.about-avatar {
  @include desktop {
    img {
      margin-left: 100px !important;
      margin-right: 0 !important;
    }
  }
}

.about-line {
  @include desktop {
    hr {
      margin-top: 30px;
      max-width: 2000px !important;
    }
  }
}

.about-follow-header {
  @include desktop {
    h2 {
      font-size: 48px !important;
      /*             text-align: left !important;
            margin-left: 100px!important; */
    }
  }
}

.about-icons {
  @include desktop {
    font-size: 40px !important;
    /*         text-align: left !important;
        margin-left: 250px!important; */
  }
}

/* React Image Gallery */
.image-gallery {
  max-width: 600px;
  max-height: 100px;

  @include desktop {
    display: block;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }
}

.image-gallery-image {
  img {
    border-radius: 10px;
  }
}

/* Tour */
.tour {
  @include desktop {
    grid-gap: 0px !important;
    grid-template-columns: 100px auto 30px 1fr 100px !important;
    grid-template-rows: repeat(4, auto) !important;
    grid-template-areas:
      ". gallery . name ."
      ". gallery . description ."
      ". gallery . info ."
      ". gallery . price ." !important;
  }
}

.tour-header {
  @include desktop {
    h2 {
      font-size: 48px !important;
      text-align: left !important;
    }
  }
}

.tour-info {
  @include desktop {
    h2 {
      font-size: 36px !important;

      &::after {
        width: 310px !important;
      }
    }

    p {
      font-size: 24px !important;
      max-width: 1000px;
    }

    i {
      font-size: 36px !important;
    }

    ul {
      font-size: 24px !important;
    }

    button {
      font-size: 24px !important;
      border-radius: 40px !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      padding-right: 45px !important;
      padding-left: 45px !important;
      margin-bottom: 50px;
      margin-right: 0 !important;
      margin-left: 0 !important;

      i {
        font-size: 24px !important;
      }
    }
  }
}

.tour-gallery {
  @include desktop {
    .image-gallery {
      margin-top: 9%;
    }
  }
}

/* Review */
.review {
  @include desktop {
    grid-template-columns: 150px 1fr !important;
  }
}

.review-comment {
  @include desktop {
    font-size: 24px !important;
  }
}

.review-name {
  @include desktop {
    font-size: 26px !important;
  }
}

/* Footer */
.footer-copyright {
  @include desktop {
    font-size: 24px !important;
  }
}
