:root {
  --mainWhite: #ffffff;
  --mainBlack: #000000;
}

body {
  font-family: "Montserrat";
  background-color: var(--mainBlack);
  color: var(--mainWhite);
}

/* Navbar */
@media (min-width: 840px) {
  .navbar {
    grid-gap: 20px !important;
    grid-template-columns: 50px 260px 1fr repeat(3, 200px) !important;
    grid-template-rows: 50px !important;
    grid-template-areas: ". logo . tours book about" !important;
  }
}

@media (min-width: 840px) {
  .navbar-logo {
    margin-top: 7px;
  }
}

@media (min-width: 840px) {
  .navbar-link {
    text-align: left !important;
    border-bottom: none !important;
  }
  .navbar-link a {
    color: #a3a3a3 !important;
  }
  .navbar-link a:hover {
    color: white !important;
    filter: drop-shadow(0px 0px 5px var(--mainWhite));
  }
}

/* Home */
@media (min-width: 840px) {
  .home {
    grid-template-columns: 100px 1fr 1fr 100px !important;
    grid-template-rows: repeat(4, auto) 50px auto !important;
    grid-template-areas: ". main photo ." ". sub photo ." ". button photo ." ". . photo ." ". line line ." ". review review ." !important;
  }
}

@media (min-width: 840px) {
  .home-main-header h1 {
    font-size: 60px !important;
  }
}

@media (min-width: 840px) {
  .home-sub-header {
    font-size: 36px !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 840px) {
  .home-photo img {
    display: block;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
  }
}

@media (min-width: 840px) {
  .home-click button {
    font-size: 32px !important;
    border-radius: 40px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
}

@media (min-width: 840px) {
  .home-line hr {
    margin-top: 30px;
    max-width: 2000px !important;
  }
}

/* Tours */
@media (min-width: 840px) {
  .tours {
    grid-template-columns: 100px 1fr 100px !important;
    grid-template-rows: 80px 100px 1fr 100px 1fr !important;
    grid-template-areas: ". header ." ". lisbon-header ." ". lisbon ." ". sintra-header ." ". sintra ." !important;
  }
}

@media (min-width: 840px) {
  .tours-header {
    font-size: 48px !important;
    text-align: left !important;
  }
}

@media (min-width: 840px) {
  .tours-subheader {
    font-size: 36px !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 840px) {
  .tours-lisbon,
  .tours-sintra {
    grid-template-columns: repeat(auto-fit, minmax(370px, 400px)) !important;
    grid-template-rows: 260px !important;
  }
  .tours-lisbon img,
  .tours-sintra img {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

/* Book */
@media (min-width: 840px) {
  .book {
    grid-template-columns: 1fr 2px 1fr !important;
    grid-template-rows: 70px 730px !important;
    grid-template-areas: "book-header book-line book-form" "book-description book-line book-form" !important;
  }
}

@media (min-width: 840px) {
  .book-header {
    font-size: 48px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

@media (min-width: 840px) {
  .book-description {
    font-size: 36px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

@media (min-width: 840px) {
  .book-form {
    zoom: 115%;
  }
}

@media (min-width: 840px) {
  .book-line hr {
    max-width: 0.5px !important;
    height: 710px !important;
  }
}

@media (min-width: 840px) {
  .book-comment p {
    right: -5px !important;
  }
  .book-comment textarea {
    right: -5px !important;
    height: 100px !important;
    width: 250px !important;
  }
}

/* About */
@media (min-width: 840px) {
  .about {
    grid-template-columns: 400px 1fr 100px !important;
    grid-template-rows: repeat(4, auto) 80px 1fr 30px 120px 120px !important;
    grid-template-areas: "header header ." "sub-header-years sub-header-years ." "text-years text-years ." "sub-header-guide sub-header-guide ." "avatar name ." "avatar description ." "line line line" "follow-header follow-header ." "icons icons ." !important;
  }
}

@media (min-width: 840px) {
  .about-header h2 {
    font-size: 48px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

@media (min-width: 840px) {
  .about-subheader h3 {
    font-size: 30px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

@media (min-width: 840px) {
  .about-name {
    font-size: 40px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

@media (min-width: 840px) {
  .about-description {
    font-size: 26px !important;
    text-align: left !important;
    margin-left: 100px !important;
  }
}

@media (min-width: 840px) {
  .about-text {
    font-size: 26px !important;
    text-align: left !important;
    margin-left: 120px !important;
  }
}

@media (min-width: 840px) {
  .about-avatar img {
    margin-left: 100px !important;
    margin-right: 0 !important;
  }
}

@media (min-width: 840px) {
  .about-line hr {
    margin-top: 30px;
    max-width: 2000px !important;
  }
}

@media (min-width: 840px) {
  .about-follow-header h2 {
    font-size: 48px !important;
    /*             text-align: left !important;
          margin-left: 100px!important; */
  }
}

@media (min-width: 840px) {
  .about-icons {
    font-size: 40px !important;
    /*         text-align: left !important;
        margin-left: 250px!important; */
  }
}

/* React Image Gallery */
.image-gallery {
  max-width: 600px;
  max-height: 100px;
}
@media (min-width: 840px) {
  .image-gallery {
    display: block;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }
}

.image-gallery-image img {
  border-radius: 10px;
}

/* Tour */
@media (min-width: 840px) {
  .tour {
    grid-gap: 0px !important;
    grid-template-columns: 100px auto 30px 1fr 100px !important;
    grid-template-rows: repeat(4, auto) !important;
    grid-template-areas: ". gallery . name ." ". gallery . description ." ". gallery . info ." ". gallery . price ." !important;
  }
}

@media (min-width: 840px) {
  .tour-header h2 {
    font-size: 48px !important;
    text-align: left !important;
  }
}

@media (min-width: 840px) {
  .tour-info h2 {
    font-size: 36px !important;
  }
  .tour-info h2::after {
    width: 310px !important;
  }
  .tour-info p {
    font-size: 24px !important;
    max-width: 1000px;
  }
  .tour-info i {
    font-size: 36px !important;
  }
  .tour-info ul {
    font-size: 24px !important;
  }
  .tour-info button {
    font-size: 24px !important;
    border-radius: 40px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-right: 45px !important;
    padding-left: 45px !important;
    margin-bottom: 50px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .tour-info button i {
    font-size: 24px !important;
  }
}

@media (min-width: 840px) {
  .tour-gallery .image-gallery {
    margin-top: 9%;
  }
}

/* Review */
@media (min-width: 840px) {
  .review {
    grid-template-columns: 150px 1fr !important;
  }
}

@media (min-width: 840px) {
  .review-comment {
    font-size: 24px !important;
  }
}

@media (min-width: 840px) {
  .review-name {
    font-size: 26px !important;
  }
}

/* Footer */
@media (min-width: 840px) {
  .footer-copyright {
    font-size: 24px !important;
  }
}/*# sourceMappingURL=App.css.map */